import React, { useState, useCallback, useMemo } from 'react';

import { navigate, graphql } from 'gatsby';

import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  Link,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';

import { useSnackbar } from '@context';

import { LoginLayout } from '@components/layout';
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
} from '@components/register';
import MultiStepForm from '@components/register/MultiStepForm';
import { useAuth } from '@context/auth';
import { useMultiStepForm } from '@hooks/useMultiStepForm';

function Register({ data }) {
  // Configuração inicial das etapas
  const initialSteps = useMemo(
    () => [
      {
        title: '',
        Icon: LockOutlinedIcon,
        completed: false,
        Page: StepOne,
      },
      {
        title: 'Informações pessoais',
        Icon: PermIdentityOutlinedIcon,
        completed: false,
        Page: StepTwo,
      },
      {
        title: 'Tipo de conta',
        Icon: SwitchAccountOutlinedIcon,
        completed: false,
        Page: StepThree,
      },
      {
        title: 'Informações profissionais',
        Icon: MedicalServicesOutlinedIcon,
        completed: false,
        Page: StepFour,
      },
      {
        title: 'Endereço pessoal',
        Icon: PlaceOutlinedIcon,
        completed: false,
        Page: StepFive,
      },
      {
        title: 'Finalizar cadastro',
        Icon: CheckIcon,
        completed: false,
        Page: StepSix,
      },
    ],
    []
  );

  // Estados locais
  const [openError, setOpenError] = useState(false);

  // Hooks
  const auth = useAuth();
  const snackbar = useSnackbar();

  // Hook personalizado para gerenciar o formulário multi-etapa
  const {
    steps,
    currentStep,
    validating,
    loading,
    setLoading,
    nextStep,
    prevStep,
    getFormData,
  } = useMultiStepForm({
    initialSteps,
  });

  // Função para enviar o formulário
  const handleSubmit = useCallback(
    async (values) => {
      try {
        // Compila todos os dados do formulário
        const formData = getFormData();

        // Adiciona os dados da última etapa
        const finalData = { ...formData, ...values };

        // Inicia o processo de registro
        setLoading(true);

        // Salva dados locais (exceto credenciais)
        const localData = { ...finalData };
        delete localData.email;
        delete localData.password;
        delete localData.passwordConfirm;

        localStorage.setItem('CEMED_user', JSON.stringify(localData));

        // Envia para o Firebase
        const response = await auth?.register(finalData);

        if (response.error) {
          setOpenError(true);
          return;
        }

        // Navegação após sucesso
        navigate('/needVerify');
      } catch (error) {
        snackbar.openMessage({
          message: 'Ocorreu um erro durante o cadastro. Tente novamente.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [auth, getFormData, snackbar, setLoading]
  );

  // Manipuladores do diálogo de erro
  const handleCloseError = () => setOpenError(false);

  const handleHelpClick = useCallback((e) => {
    e.preventDefault();
    navigate('/central_de_ajuda');
  }, []);

  return (
    <>
      <MultiStepForm
        steps={steps}
        currentStep={currentStep}
        validating={validating}
        loading={loading}
        onNext={nextStep}
        onPrev={prevStep}
        onSubmit={handleSubmit}
        imageData={data}
        homePath="/"
      />

      {/* Diálogo de erro */}
      <Dialog
        open={openError}
        onClose={handleCloseError}
        className="rounded-lg"
      >
        <DialogTitle className="flex">
          <IconButton
            className="ml-auto"
            onClick={handleCloseError}
            aria-label="Fechar"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="flex flex-col items-center gap-3 [&_a]:text-[var(--gray-3)] [&_a]:underline">
          <Typography variant="body1" color="error" align="center">
            Seu cadastro não pode ser concluído, verifique as informações e
            tente novamente.
          </Typography>

          <Typography variant="body1">
            Qualquer dúvida acesse nossa{' '}
            <Link href="#" onClick={handleHelpClick}>
              Central de Ajuda
            </Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Index(props) {
  return (
    <LoginLayout>
      <Register {...props} />
    </LoginLayout>
  );
}

export const registerQuery = graphql`
  {
    allFile(filter: { relativePath: { glob: "register/*.png" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`;

export default Index;
