import { useState, useCallback, useMemo } from 'react';

/**
 * Hook para gerenciar formulários de múltiplas etapas
 * @param {Object} options - Opções de configuração
 * @param {Array} options.initialSteps - Array inicial de etapas
 * @param {number} options.initialStep - Etapa inicial (padrão: 0)
 */
export function useMultiStepForm({ initialSteps, initialStep = 0 }) {
  const [steps, setSteps] = useState(initialSteps);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [validating, setValidating] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Atualiza os resultados de uma etapa
   */
  const updateStepResult = useCallback((stepIndex, values) => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        result: { ...values },
        completed: true,
      };
      return updatedSteps;
    });
  }, []);

  /**
   * Manipulador para avançar para a próxima etapa
   */
  const nextStep = useCallback(() => {
    setValidating({
      currentStep,
      success: (values) => {
        setValidating(null);

        // Atualiza o resultado da etapa atual com os valores validados
        updateStepResult(currentStep, values);

        // Lógica para pular etapas usando values (dados atuais) em vez de steps[2].result
        setCurrentStep((current) => {
          // Se estamos na etapa 2 (Tipo de conta) e a seleção é para Gestor (0)
          if (current === 2 && values?.selected === 0) {
            return current + 2; // Pula para a etapa 4 (endereço)
          }
          return current + 1; // Avança normalmente
        });
      },
      error: () => setValidating(null),
    });
  }, [currentStep, updateStepResult]);

  /**
   * Manipulador para voltar para a etapa anterior
   */
  const prevStep = useCallback(() => {
    setCurrentStep((current) => {
      // Lógica para pular etapas ao voltar (se o tipo de conta for gestor)
      if (current === 4 && steps[2]?.result?.selected === 0) {
        return current - 2;
      }
      return current - 1;
    });
  }, [steps]);

  /**
   * Ir para uma etapa específica
   */
  const goToStep = useCallback(
    (stepIndex) => {
      if (stepIndex === currentStep) return;

      setValidating({
        currentStep,
        success: (values) => {
          setValidating(null);
          updateStepResult(currentStep, values);
          setCurrentStep(stepIndex);
        },
        error: () => setValidating(null),
      });
    },
    [currentStep, updateStepResult]
  );

  /**
   * Compila todos os resultados das etapas em um único objeto
   */
  const getFormData = useCallback(() => {
    return steps.reduce((result, step) => {
      return { ...result, ...(step.result || {}) };
    }, {});
  }, [steps]);

  /**
   * Define resultados para todas as etapas (útil para pré-preencher na edição)
   */
  const setStepsData = useCallback((data) => {
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        // Aqui você aplicaria a lógica para mapear dados para cada etapa específica
        // Este é um exemplo simplificado
        return {
          ...step,
          result: data[index] ? { ...data[index] } : step.result,
          completed: data[index] ? true : step.completed,
        };
      });
    });
  }, []);

  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;

  return {
    steps,
    currentStep,
    validating,
    loading,
    setLoading,
    nextStep,
    prevStep,
    goToStep,
    getFormData,
    setStepsData,
    updateStepResult,
    isLastStep,
    isFirstStep,
  };
}
